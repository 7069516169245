
.galleryPage{
 
    h1{
        font-family: 'Raleway', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 42px;
        color:$orangeSec;
        font-style: italic;
        font-weight: 800;
   
        padding: 5rem 10rem 1rem 10rem;
        
    }
}
button{
    display: block;
    color: white;
    font-family: 'Raleway', sans-serif;
    text-transform: uppercase;
    text-decoration: none;
    font-style: italic;
    font-weight: 800;

    margin: 0rem 10rem;
    &:hover{
        background-color: $orangeOne;
    }
}
.imageGrid{
    margin: 20px auto;
    padding: 0 20px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 40px;
}
.imageWrap{
overflow: hidden;
height: 0;
padding: 50% 0;
position: relative;
opacity: 0.8;

img{
    display: block;
    margin: auto;
    
    min-height: 100%;
    min-width: 100%;
    max-width: 150%;
    position: absolute;
    cursor: pointer;
    top:0;
    left: 0;
    
}
}

.bigImage{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.479);
    img{
        display: block;
        max-width: 60%;
        max-height: 80%;
        margin: 60px auto;
        box-shadow: 3px 5px 7px  rgba(0, 0, 0, 0.521);;
        border: 2px solid $orangeSec;
    }
}
@media(max-width:1024px){
    .galleryPage{
        h1{
        padding: 2rem 1rem;
        }
        button{
            margin: 0rem 1rem 1rem 1rem;
        }
       

        .imageGrid{
            margin: 20px auto;
            padding: 0 20px;
            display: grid;
            grid-template-columns: 1fr 1fr ;
            grid-gap: 40px;
        }

        .bigImage{
            img{
            max-width: 90%;
            max-height: 90%
        }
        }
    }
    }

    @media(max-width:500px){
        .galleryPage{
        .imageGrid{
            margin: 20px auto;
            padding: 0 10px;
            display: grid;
            grid-template-columns: 1fr;
            grid-gap: 10px;
        }
     
    }
    }
    @media(min-width:501px){
        .galleryPage{
            .imageWrap{
                transition: all ease 0.1s;
                &:hover{
                    transform: scale(1.03);
                }
            }

        }
    }