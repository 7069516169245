@import url('https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Display:ital@1&family=Montserrat&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@200;300&display=swap');
$dark:#262425;
$orangeSec: #F27405;
$orangeOne: #F25C05;
$brownOne:  #3f280b;

@import "./wave";
@import "./contact";
@import "./gallery";
@import "./aboutus";

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Raleway', sans-serif;
}

button{

margin: auto;
   
    cursor: pointer;
background: transparent;
    padding: 1rem 2rem;
    border: 3px solid $orangeSec;
    
    transition: all 0.5s ease;

    a{
        font-weight: bold;
        color:$orangeSec;
        font-size: 1.1rem;
        text-decoration: none;

    }

&:hover{
    background-color:  $orangeSec;
    a{
    color:white;
}
}
}

p{
    padding: 15px 0;
}


body{
    background-color: $dark;
}


nav{
    background-color: #2c2c2c;
    color: $dark;

display: flex;
margin: auto;
justify-content: space-between;

font-size: 14px;
padding: 20px 10rem;
h3{
 
        flex:2;
        a{
        font-family: 'Raleway', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 42px;
        color: white;
        font-style: italic;
        font-weight: 800;
  }
}
.menu{
    flex:1;
    display: flex;
    margin-right: 44px;
    justify-content: space-evenly;
    align-items: center;
h1{
    padding: 0;
    font-size: 14px;
    a{
        font-family: 'Montserrat', sans-serif;
        color: white;
        font-size: 18px;
        text-decoration: none;
        
    }
}
}

.navLine{
    height: 0.2rem; 
    background-color: $orangeSec;
    width: 0%;
    position: absolute;
    bottom: -40%;
    left: 2%;
}

}

footer{
    background-color: $dark;
    display: flex;
    justify-content: flex-end;
    .footerContent{
        width: 30%;
        background-color:  #2c2c2c;
        color:$orangeSec;
        padding: 15px 20px;
        text-align: right;
        font-size: 18px;
    }
}


@media(max-width:1024px){
    nav{
        padding: 20px 2rem;
        flex-direction: column;

        h3{
            text-align: center;
            padding-bottom: 20px;
        }

        .menu{
           align-items: center;
           margin-right: 0;
        }
    }

    footer{
        justify-content: center;
        .footerContent{
            width: 100%;
        }
    }
}