

.contactPage{
    padding: 5rem 10rem;
    min-height: 90vh;
    h1{
        font-family: 'Raleway', sans-serif;
        text-transform: uppercase;
        text-decoration: none;
        font-size: 42px;
        color:$orangeSec;
        font-style: italic;
        font-weight: 800;
        padding-bottom: 4rem;
    }


.contactContainer{
    .hidden{
    overflow: hidden;
}
    display: flex; 
    justify-content: center;


.contactInfo{
    flex:1;
}
.contactFormContainer{
    .contactForm .hidden{
        overflow: hidden;
    }
    z-index: 2;
    flex:2;
    .mapContainer{
       iframe{
        width: 100%;
        height: 400px;
       }
    }
}

.contactBox{
   z-index: 2;
    display: flex;
    justify-content: flex-start;
    align-items: center;


    padding: 20px;
    padding-left: 0;
    h3{
        z-index: 2;
        font-family: 'Nunito Sans', sans-serif;
        font-weight: 300;
        align-self: center;
        color: white;
        font-size: 18px;
        letter-spacing: 0.4px;
    }
    .icon{
        z-index: 2;
    margin-right: 10px;
    color: $orangeOne;
    }
}
}
}
@media(max-width:1024px){
.contactPage{
    padding: 5rem 1rem;
    .contactContainer{
        flex-direction: column;
        
    }
    .contactFormContainer{
        margin-top: 60px;
    }
}
}