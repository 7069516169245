.aboutSection{


    color: white;
    min-height: 90vh;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;

    button{
        margin: 0;
    }


    .description{
        z-index: 2; 

        .hide{
            overflow: hidden;
        }
    }
.image{
    z-index: 2;
    img{
        width: 570px;
      
    }}

    h1{
        
        padding: 20px 0;
        font-size: 72px;
    }

    h2{
        
        span{
        color:$orangeOne;
        }
    }

    p{
       
        font-size: 16px;
        padding-bottom: 55px;
    }
}


.faqSection{
    color: white;
    min-height: 90vh;
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;
    h2{
        
        padding-bottom: 2rem;
        font-size: 42px;

        
    }
    h4{  
    cursor: pointer;
    font-size: 20px;
    transition: all 0.2s ease-in-out;
    &:hover{
        color:$orangeSec;
    }
}
  

    .faq-line{
        background-color: $orangeSec;
        height: 3px;
        margin: 20px 0px;
        width: 100%;
        
    }
    .question{
        padding: 20px 0;
        cursor: pointer;
    }

    .answer{
        padding: 20px 0;
        p{
            padding: 10px 0;
        }
    }
}

.workSection{
    color: white;
    min-height: 90vh;
    display: block;
    align-items: center;
    justify-content: space-between;
    padding: 5rem 10rem;

    h1{
        padding-bottom: 40px;
    }
    .step{
        display: flex;
        margin-bottom: 40px;
        background-color: rgba(0, 0, 0, 0.26);
        .workDescription{
          font-size: 18px;
            padding:40px;
            line-height: 30px;
        flex: 1;
        }
        .workImage{
            min-height: 400px;
         flex:1;
         background-image: url('../images/stal.jpg');
      background-size: cover;
      background-repeat: no-repeat;
      background-position: 50% 60%;
      
        }
    }
}

@media(max-width:1399px){
    .aboutSection,.faqSection,.workSection{
        padding: 5rem 5rem;
    }
  
}
@media(max-width:1127px){
    .aboutSection,.faqSection,.workSection{
        padding: 4rem 2rem;
    }
}
@media(max-width:1024px){
    .aboutSection{
      flex-direction: column;
      .description{
          padding-bottom: 60px;
       text-align: center;
      }
      button{
          margin: auto;
      }
    }
    .workSection{
        .step{
            flex-direction: column;
        }
    }
}
@media(max-width:600px){
    .aboutSection{
        .image{
            margin: auto;
            width: 100%;
            img{
               width: 100%;
            }

        }
    }
    .workSection{
        h1{text-align: center;}
        .step{
        .workDescription{
            line-height: 17px;
            
        }
        
    }}
}