.waveAbout{
    position: absolute;
    left:0;
    top:30%;
   z-index: 1;
}

.contactPage{
.waveAbout{
    top:55%; 
}

}

